import { BehaviorSubject, Observable, Subscription } from "rxjs";
import * as localForage from "localforage";

import { SettingsService } from "./settings.service";
import { MenuService } from "./menu.service";
import { IDemoSettings } from "../models/settings.types";
import {
  DB_SETTINGS,
  DB_SETTINGS_FLAG,
  DEMO_DEFAULT_SETTINGS,
  SETTINGS_STATE_FLAGS
} from "./settings.config";
import { DemoState } from "../models/demo.types";

class _DemoService {
  private demoStateSubject: BehaviorSubject<DemoState> = new BehaviorSubject<
    DemoState
  >(DemoState.INITIALIZING);
  observeDemoState$ = (): Observable<DemoState> =>
    this.demoStateSubject.asObservable();

  demoSettingsSub: Subscription | undefined = undefined;

  get demoState(): DemoState {
    return this.demoStateSubject.getValue();
  }

  set demoState(newState: DemoState) {
    this.demoStateSubject.next(newState);
  }

  async initialize(): Promise<void> {
    const setFlag: string | null = await localForage.getItem(DB_SETTINGS_FLAG);
    let dbSettings: IDemoSettings | null = await localForage.getItem(
      DB_SETTINGS
    );

    if (!setFlag) {
      await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.UNSET);
    }

    if (!dbSettings) {
      dbSettings = DEMO_DEFAULT_SETTINGS;
      await localForage.setItem(DB_SETTINGS, dbSettings);
    }

    await MenuService.clearError();
    await SettingsService.initialize();
  }

  reset = async () => {
    await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.UNSET);
  };

  transitionToInitializing = async (): Promise<void> => {
    DemoService.demoState = DemoState.INITIALIZING;
  };

  transitionToInitialized = async (): Promise<void> => {
    DemoService.demoState = DemoState.INITIALIZED;
  };

  makeReady = async (demoSettings: IDemoSettings): Promise<void> => {
    await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.SET);
    await SettingsService.save(demoSettings);
  };

  transitionToReady = async (): Promise<void> => {
    DemoService.demoState = DemoState.READY;
  };

  isDemoInitializing = (demoState: DemoState | undefined) => {
    return demoState === DemoState.INITIALIZING;
  };

  areSettingsReady = async () => {
    const setFlag: string | null = await localForage.getItem(DB_SETTINGS_FLAG);
    let dbSettings: IDemoSettings | null = await localForage.getItem(
      DB_SETTINGS
    );

    if (!setFlag) {
      return false;
    }

    return setFlag === SETTINGS_STATE_FLAGS.SET && !!dbSettings;
  };

  isDemoInitialized = (demoState: DemoState | undefined) => {
    return demoState === DemoState.INITIALIZED;
  };

  isDemoReady = (demoState: DemoState | undefined) => {
    return demoState === DemoState.READY;
  };
}

export const DemoService: _DemoService = new _DemoService();
