import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import HomeView from "./components/views/home-view";
import MenuView from "./components/views/menu-view";
import EditItemView from "./components/views/edit-item-view";
import NotFoundView from "./components/views/not-found-view";
import SettingsView from "./components/views/settings-view";
import UserProfileView from "./components/views/user-profile-view";
import AddItemView from "./components/views/add-item-view";

import NavBar from "./components/layout/nav-bar";
import MenuItemView from "./components/views/menu-item-view";
import ProtectedRoute from "./components/security/protected-route";
import MenuAdminRoute from "./components/security/menu-admin-route";

import { AppService } from "./services/app.service";

import { Subscription } from "rxjs";

import "./app.scss";
import { SettingsService } from "./services/settings.service";

import { AppState } from "./models/app.types";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import DeleteItemView from "./components/views/delete-item-view";
import { DemoService } from "./services/demo.service";
import { SecurityService } from "./services/security.service";
import { Loader } from "./components/ui/loader";

interface IAppContent {
  appName: string;
}

const AppContent: React.FC<IAppContent> = ({ appName }) => {
  return (
    <>
      <NavBar appName={appName} />
      <Switch>
        <Redirect exact to="/home" from="/" />
        <Route path="/home" component={HomeView} />
        <Route
          path="/menu"
          render={(props: RouteComponentProps) => {
            const path = props.match.path;

            return (
              <Switch>
                <Route exact path={`${path}/`} component={MenuView} />
                <MenuAdminRoute
                  {...props}
                  redirectPath="/menu"
                  path={`${path}/add-item`}
                  component={AddItemView}
                />
                <MenuAdminRoute
                  {...props}
                  redirectPath="/menu"
                  path={`${path}/:menuItemId/edit-item`}
                  component={EditItemView}
                />
                <MenuAdminRoute
                  {...props}
                  redirectPath="/menu"
                  path={`${path}/:menuItemId/delete-item`}
                  component={DeleteItemView}
                />
                <Route path={`${path}/:menuItemId`} component={MenuItemView} />
              </Switch>
            );
          }}
        />

        <Route path="/settings" component={SettingsView} />
        <ProtectedRoute path="/user-profile" component={UserProfileView} />
        <Route component={NotFoundView} />
      </Switch>
    </>
  );
};

const App: React.FC<WithAuth0Props> = ({ auth0 }) => {
  const [appState, setAppState] = React.useState<AppState | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [authentication, setAuthentication] = React.useState<
    boolean | undefined
  >(undefined);

  const APP_NAME: string = `WHATABYTE`;

  const { isLoading: isAuth0Loading } = auth0;

  React.useEffect(() => {
    const demoStateSub: Subscription = DemoService.observeDemoState$().subscribe(
      async demoState => {
        if (DemoService.isDemoReady(demoState)) {
          await AppService.transitionToInitializing();
        }
      }
    );

    return () => demoStateSub.unsubscribe();
  }, []);

  React.useEffect(() => {
    const appStateSub: Subscription = AppService.observeAppState$().subscribe(
      async appState => {
        setAppState(appState);
      }
    );

    return () => appStateSub.unsubscribe();
  }, []);

  React.useEffect(() => {
    const demoSettingsSubscription: Subscription = SettingsService.observeSettings$().subscribe(
      settings => {
        if (settings) {
          setAuthentication(settings.authentication);
        }
      }
    );

    return () => demoSettingsSubscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    const transitions = async () => {
      if (appState === undefined) {
      }

      if (AppService.isInitializing(appState)) {
        if (authentication === true && !isAuth0Loading) {
          await SecurityService.initialize(auth0);
          await AppService.initialize();
          await AppService.transitionToInitialized();
        }

        if (authentication === false) {
          await AppService.initialize();
          await AppService.transitionToInitialized();
        }
      }

      if (AppService.isInitialized(appState)) {
        setIsLoading(false);
      }
    };

    transitions();
  }, [appState, authentication, isAuth0Loading, auth0]);

  if (appState === undefined) {
    return (
      <div className="app">
        <div id="mask" />
        <Loader />
      </div>
    );
  }

  if (authentication === undefined) {
    return <Loader />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (AppService.isInitializing(appState)) {
    return (
      <div className="app">
        <div id="mask" />
        <Loader />
      </div>
    );
  }

  if (AppService.isInitialized(appState)) {
    if (!authentication) {
      return (
        <div className="app">
          <div id="mask" />
          <AppContent appName={APP_NAME} />
        </div>
      );
    }

    if (authentication && !isAuth0Loading) {
      return (
        <div className="app">
          <div id="mask" />
          <AppContent appName={APP_NAME} />
        </div>
      );
    }
  }

  return null;
};

export default withAuth0(App);
