import { BehaviorSubject, Observable } from "rxjs";

import { MenuService } from "./menu.service";

import { AppState } from "../models/app.types";

class _AppService {
  private appStateSubject: BehaviorSubject<
    AppState | undefined
  > = new BehaviorSubject<AppState | undefined>(undefined);
  observeAppState$ = (): Observable<AppState | undefined> =>
    this.appStateSubject.asObservable();

  get appState(): AppState | undefined {
    return this.appStateSubject.getValue();
  }

  set appState(newState: AppState | undefined) {
    this.appStateSubject.next(newState);
  }

  initialize = async (): Promise<void> => {
    await MenuService.initialize();
    await MenuService.fetchMenu();
  };

  transitionToIdle = async (): Promise<void> => {
    AppService.appState = AppState.IDLE;
  };

  transitionToInitializing = async (): Promise<void> => {
    AppService.appState = AppState.INITIALIZING;
  };

  transitionToInitialized = async (): Promise<void> => {
    AppService.appState = AppState.INITIALIZED;
  };

  isIdle = (appState: AppState | undefined): boolean =>
    appState === AppState.IDLE;
  isInitializing = (appState: AppState | undefined): boolean =>
    appState === AppState.INITIALIZING;
  isInitialized = (appState: AppState | undefined): boolean =>
    appState === AppState.INITIALIZED;
}

export const AppService: _AppService = new _AppService();
