import React from "react";
import { Subscription } from "rxjs";
import { Route, Redirect } from "react-router-dom";

import App from "./app";
import DemoSettingsView from "./components/views/demo-settings-view";
import Auth0ProviderWithHistory from "./components/security/auth0-provider-with-history";

import { DemoService } from "./services/demo.service";
import { SettingsService } from "./services/settings.service";

import { DemoState } from "./models/demo.types";

const Demo: React.FC = React.memo(() => {
  const [demoState, setDemoState] = React.useState<DemoState | undefined>(
    undefined
  );
  const [requiresAuthentication, setRequiresAuthentication] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    const demoStateSubscription: Subscription = DemoService.observeDemoState$().subscribe(
      async demoState => {
        setDemoState(demoState);
      }
    );

    return () => demoStateSubscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    const demoSettingsSubscription: Subscription = SettingsService.observeSettings$().subscribe(
      settings => {
        if (!settings) {
          return;
        }

        setRequiresAuthentication(settings.authentication);
      }
    );

    return () => demoSettingsSubscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    const initDemoService = async () => {
      if (demoState === undefined) {
        await DemoService.transitionToInitializing();
        return;
      }

      if (DemoService.isDemoInitializing(demoState)) {
        await DemoService.initialize();

        if (await DemoService.areSettingsReady()) {
          await DemoService.transitionToReady();
        } else {
          await DemoService.transitionToInitialized();
        }
      }
    };

    initDemoService();
  }, [demoState]);

  if (demoState === undefined) {
    return null;
  }

  if (requiresAuthentication === undefined) {
    return null;
  }

  if (DemoService.isDemoInitialized(demoState)) {
    return (
      <>
        <Redirect to="/" from="/*" />
        <Route path="/" component={DemoSettingsView} />
      </>
    );
  }

  if (DemoService.isDemoReady(demoState) && !requiresAuthentication) {
    return <App />;
  }

  if (DemoService.isDemoReady(demoState) && requiresAuthentication) {
    return (
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    );
  }

  return null;
});

export default Demo;
