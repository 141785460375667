import { IDemoSettings } from "../models/settings.types";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { SettingsService } from "./settings.service";
import { BehaviorSubject, Observable } from "rxjs";

class _SecurityService {
  private auth0: Auth0ContextInterface | undefined = undefined;

  private hasAdminAccessSubject: BehaviorSubject<
    boolean | undefined
  > = new BehaviorSubject<boolean | undefined>(undefined);
  observeHasAdminAccess$ = (): Observable<boolean | undefined> =>
    this.hasAdminAccessSubject.asObservable();

  set hasAdminAccess(hasAccess: boolean | undefined) {
    this.hasAdminAccessSubject.next(hasAccess);
  }

  initialize = (auth0: Auth0ContextInterface) => {
    if (!auth0) {
      return;
    }

    this.auth0 = auth0;

    SettingsService.observeSettings$().subscribe(
      (demoSettings: IDemoSettings | undefined) => {
        if (!demoSettings) {
          return;
        }

        const { authentication, rbac, userRole } = demoSettings;
        const { user } = auth0 as Auth0ContextInterface;

        if (!authentication) {
          this.hasAdminAccess = true;
          return;
        }

        if (!rbac && !userRole) {
          this.hasAdminAccess = true;
          return;
        }

        if (!userRole) {
          this.hasAdminAccess = false;
          return;
        }

        if (!user) {
          this.hasAdminAccess = false;
          return;
        }

        const userRoles: string[] = user[`${demoSettings.audience}/roles`];

        this.hasAdminAccess = userRoles
          ? userRoles.includes(demoSettings.userRole)
          : false;
      }
    );
  };

  getAccessTokenSilently = async () => {
    if (!this.auth0) {
      return undefined;
    }
    return await this.auth0.getAccessTokenSilently();
  };
}

export const SecurityService = new _SecurityService();
