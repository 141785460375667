import React, { ComponentClass, FunctionComponent } from "react";
import ProtectedRoute from "./protected-route";
import { Subscription } from "rxjs";
import { SecurityService } from "../../services/security.service";

interface IMenuAdminRouteProps {
  path: string;
  component: ComponentClass<any, any> | FunctionComponent<any>;
  redirectPath?: string;
}

const MenuAdminRoute: React.FC<IMenuAdminRouteProps> = props => {
  const [hasMenuAdminAccess, setHasMenuAdminAccess] = React.useState<
    boolean | undefined
  >(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const dataSubscription: Subscription = SecurityService.observeHasAdminAccess$().subscribe(
      hasAccess => {
        setHasMenuAdminAccess(hasAccess);
        setLoading(false);
      }
    );

    return () => dataSubscription.unsubscribe();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ProtectedRoute
      canActivate={hasMenuAdminAccess}
      redirectPath={props.redirectPath}
      {...props}
    />
  );
};

export default MenuAdminRoute;
