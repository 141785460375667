import React from "react";

import Content from "../layout/content";
import View from "../layout/view";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

const HomeView: React.FC<WithAuth0Props> = props => {
  const { isAuthenticated, user } = props.auth0;
  return (
    <View>
      {isAuthenticated ? (
        <Content title={`Hello, ${user.name}`} />
      ) : (
        <Content title="Howdy">
          <h3>To start using the Dashboard, please sign in.</h3>
        </Content>
      )}
    </View>
  );
};

export default withAuth0(HomeView);
