import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons/faStroopwafel";

import "./loader.scss";

export const Loader: React.FC = () => (
  <div className="loader">
    <FontAwesomeIcon icon={faStroopwafel} spin />
  </div>
);
