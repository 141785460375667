import React from "react";

import "./form-field.scss";

import { useForm } from "react-hook-form";

interface FormFieldProps {
  label: string;
  inputName: string;
  placeholder?: string;
  defaultValue?: string | number;
  required?: boolean;
  errors: any;
  register: ReturnType<typeof useForm>["register"];
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  inputName,
  placeholder,
  required,
  defaultValue,
  errors,
  register
}) => {
  return (
    <div className="form-field">
      <div>
        <label className="form-field__label" htmlFor={inputName}>
          {label}
          {required && (
            <span className="form-field__label--required">{` *`}</span>
          )}
        </label>
        {errors && <span className="form-field__error">{errors.message}</span>}
      </div>
      <input
        className="form-field__input"
        id={inputName}
        name={inputName}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={register}
      />
    </div>
  );
};

export default FormField;
