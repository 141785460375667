import React from "react";

import View, { ViewStates } from "../layout/view";
import Content from "../layout/content";
import { MenuService } from "../../services/menu.service";
import Button from "../ui/button";

import { combineLatest, Subscription } from "rxjs";
import { IMenuItem } from "../../models/menu.types";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { SettingsService } from "../../services/settings.service";
import { IDemoSettings } from "../../models/settings.types";

import "./menu-item-view.scss";
import { useHistory, useParams } from "react-router-dom";
import { SecurityService } from "../../services/security.service";

interface IParamTypes {
  menuItemId: string;
}

interface IMenuItemProps {
  selectedMenuItem: IMenuItem;
}

const MenuItem: React.FC<IMenuItemProps> = ({ children, selectedMenuItem }) => {
  return (
    <div className="menu-item">
      <img
        className="menu-item__image"
        src={selectedMenuItem.image}
        alt={`${selectedMenuItem.name}`}
      />
      <span className="menu-item__price">${selectedMenuItem.price / 100}</span>
      <div className="menu-item__details">
        <span>{selectedMenuItem.description}</span>
      </div>
      {children}
    </div>
  );
};

const MenuItemView: React.FC<WithAuth0Props> = ({ auth0 }) => {
  const [hasMenuAdminAccess, setHasMenuAdminAccess] = React.useState<
    boolean | undefined
  >(undefined);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [viewStatus, setViewStatus] = React.useState<ViewStates | undefined>(
    undefined
  );

  const [authentication, setAuthentication] = React.useState<
    boolean | undefined
  >(undefined);

  const [selectedMenuItem, setSelectedMenuItem] = React.useState<
    IMenuItem | undefined
  >(undefined);

  const history = useHistory();
  let { menuItemId: menuItemIdParam } = useParams<IParamTypes>();
  const menuItemId = parseInt(menuItemIdParam, 10);

  React.useEffect(() => {
    const dataSubscription: Subscription = combineLatest([
      SecurityService.observeHasAdminAccess$(),
      SettingsService.observeSettings$()
    ]).subscribe(
      async (data: [boolean | undefined, IDemoSettings | undefined]) => {
        const hasAccess = data[0];
        const settings = data[1];
        const selectedMenuItem = await MenuService.getMenuItem(menuItemId);

        if (!settings) {
          return;
        }

        if (selectedMenuItem) {
          setHasMenuAdminAccess(hasAccess);
          setLoading(false);
          setViewStatus(ViewStates.Valid);
          setAuthentication(settings.authentication);
          setSelectedMenuItem(selectedMenuItem);

          return;
        }

        setHasMenuAdminAccess(hasAccess);
        setLoading(false);
        setViewStatus(ViewStates.NotFound);
        setAuthentication(settings.authentication);
      }
    );

    return () => dataSubscription.unsubscribe();
  }, [menuItemId]);

  const back = async () => {
    history.push("/menu");
  };

  const { isAuthenticated } = auth0;

  if (loading) {
    return null;
  }

  if (viewStatus === ViewStates.NotFound) {
    return (
      <View viewStatus={viewStatus}>
        <Button label="View menu items" action={() => history.push("/menu")} />
      </View>
    );
  }

  if (!selectedMenuItem) {
    return (
      <View viewStatus={viewStatus}>
        <Button label="View menu items" action={() => history.push("/menu")} />
      </View>
    );
  }

  if (viewStatus === ViewStates.Valid) {
    return (
      <View viewStatus={viewStatus}>
        <Content title={selectedMenuItem.name} actionName="Back" action={back}>
          <MenuItem selectedMenuItem={selectedMenuItem}>
            {!authentication && (
              <div className="menu-item__actions">
                <Button
                  label="Edit"
                  size="xsmall"
                  action={() => {
                    selectedMenuItem &&
                      history.push(`/menu/${selectedMenuItem.id}/edit-item/`);
                  }}
                />
                <Button
                  label="Delete"
                  size="xsmall"
                  action={() => {
                    selectedMenuItem &&
                      history.push(`/menu/${selectedMenuItem.id}/delete-item/`);
                  }}
                />
              </div>
            )}
            {authentication && hasMenuAdminAccess && isAuthenticated && (
              <div className="menu-item__actions">
                <Button
                  label="Edit"
                  size="xsmall"
                  action={() => {
                    selectedMenuItem &&
                      history.push(`/menu/${selectedMenuItem.id}/edit-item/`);
                  }}
                />
                <Button
                  label="Delete"
                  size="xsmall"
                  action={() => {
                    selectedMenuItem &&
                      history.push(`/menu/${selectedMenuItem.id}/delete-item/`);
                  }}
                />
              </div>
            )}
          </MenuItem>
        </Content>
      </View>
    );
  }

  return null;
};

export default withAuth0(MenuItemView);
