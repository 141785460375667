export interface IAppStates {
  IDLE: string;
  INITIALIZING: string;
  INITIALIZED: string;
}

export enum AppState {
  IDLE = "[APP] IDLE",
  INITIALIZING = "[APP] INITIALIZING",
  INITIALIZED = "[APP] INITIALIZED"
}
