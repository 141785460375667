import { BehaviorSubject, Observable } from "rxjs";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { SettingsService } from "./settings.service";

import history from "../history";
import {
  IBaseMenuItem,
  IMenuError,
  IMenuItem,
  IMenuItems
} from "../models/menu.types";
import { IDemoSettings } from "../models/settings.types";
import { SecurityService } from "./security.service";

class _MenuService {
  demoSettings: IDemoSettings | undefined = undefined;
  // private auth0: Auth0ContextInterface | undefined = undefined;

  private menuSubject: BehaviorSubject<IMenuItems> = new BehaviorSubject({});
  observeMenu$ = (): Observable<IMenuItems> => this.menuSubject.asObservable();

  private menuErrorSubject: BehaviorSubject<IMenuError | null> = new BehaviorSubject<IMenuError | null>(
    null
  );
  observeMenuError$ = (): Observable<IMenuError | null> =>
    this.menuErrorSubject.asObservable();

  private selectedMenuItemSubject: BehaviorSubject<
    IMenuItem | undefined
  > = new BehaviorSubject<IMenuItem | undefined>(undefined);
  observeSelectedMenuItem$ = (): Observable<IMenuItem | undefined> =>
    this.selectedMenuItemSubject.asObservable();

  clearMenuError() {
    this.menuErrorSubject.next(null);
  }

  get selectedMenuItem(): IMenuItem | undefined {
    return this.selectedMenuItemSubject.getValue();
  }

  set selectedMenuItem(newSelectedItem: IMenuItem | undefined) {
    this.selectedMenuItemSubject.next(newSelectedItem);
  }

  initialize = async () => {
    SettingsService.observeSettings$().subscribe(
      (demoSettings: IDemoSettings | undefined) => {
        this.demoSettings = demoSettings;
      }
    );
  };

  getMenuItem = async (id: number): Promise<IMenuItem | undefined> => {
    const state: IMenuItems = this.menuSubject.getValue();

    const item: IMenuItem = Object.values(state).filter(
      item => item.id === id
    )[0];

    if (item) {
      this.selectedMenuItem = item;
      return item;
    }

    return undefined;
  };

  addMenuItem = async (newMenuItem: IBaseMenuItem) => {
    if (!(this.demoSettings && this.demoSettings.apiUrl)) {
      return;
    }

    const { apiUrl, authentication } = this.demoSettings;

    const url: string = new URL("/api/menu/items", apiUrl).href;

    let options: AxiosRequestConfig | undefined = undefined;

    if (!authentication) {
      options = {
        method: "POST",
        data: newMenuItem,
        url
      };
    }

    if (authentication) {
      const token:
        | string
        | undefined = await SecurityService.getAccessTokenSilently();

      if (!token) {
        return;
      }

      options = {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        data: newMenuItem,
        url
      };
    }

    if (!options) {
      return;
    }

    try {
      await axios(options);
      await this.fetchMenu();

      history.push("/menu");
    } catch (e) {
      const error = e as AxiosError;
      let errorMessage = undefined;

      if (error.response) {
        const { data, status } = error.response;
        const { message } = data;

        errorMessage = message || "Unable to add item";

        if (status === 401) {
          errorMessage = message || "Unauthorized";
        }

        this.menuErrorSubject.next({ status, message: errorMessage });
      }
    }
  };

  updateMenuItem = async (payload: IMenuItem) => {
    if (!(this.demoSettings && this.demoSettings.apiUrl)) {
      return;
    }

    const { apiUrl, authentication } = this.demoSettings;

    const { id, ...baseMenuProperties } = payload;
    const menuData: IBaseMenuItem = baseMenuProperties;

    const url: string = new URL(`/api/menu/items/${id}`, apiUrl).href;

    let options: AxiosRequestConfig | undefined = undefined;

    if (!authentication) {
      options = {
        method: "PUT",
        data: menuData,
        url
      };
    }

    if (authentication) {
      const token:
        | string
        | undefined = await SecurityService.getAccessTokenSilently();

      if (!token) {
        return;
      }

      options = {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        data: menuData,
        url
      };
    }

    if (!options) {
      return;
    }

    try {
      await axios(options);
      await this.fetchMenu();

      history.push("/menu");
    } catch (e) {
      const error = e as AxiosError;
      let errorMessage = undefined;

      if (error.response) {
        const { data, status } = error.response;
        const { message } = data;

        errorMessage = message || "Unable to update item";

        if (status === 401) {
          errorMessage = message || "Unauthorized";
        }

        this.menuErrorSubject.next({ status, message: errorMessage });
      }
    }
  };

  deleteMenuItem = async (itemId: number) => {
    if (!(this.demoSettings && this.demoSettings.apiUrl)) {
      return;
    }

    const { apiUrl, authentication } = this.demoSettings;

    const url: string = new URL(`/api/menu/items/${itemId}`, apiUrl).href;

    let options: AxiosRequestConfig | undefined = undefined;

    if (!authentication) {
      options = {
        method: "DELETE",
        url
      };
    }

    if (authentication) {
      const token:
        | string
        | undefined = await SecurityService.getAccessTokenSilently();

      if (!token) {
        return;
      }

      options = {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
        url
      };
    }

    if (!options) {
      return;
    }

    try {
      await axios(options);
      await this.fetchMenu();

      history.push("/menu");
    } catch (e) {
      const error = e as AxiosError;
      let errorMessage = undefined;

      if (error.response) {
        const { data, status } = error.response;
        const { message } = data;

        errorMessage = message || "Unable to delete item";

        if (status === 401) {
          errorMessage = message || "Unauthorized";
        }

        this.menuErrorSubject.next({ status, message: errorMessage });
      }
    }
  };

  fetchMenu = async () => {
    if (!(this.demoSettings && this.demoSettings.apiUrl)) {
      return;
    }

    const baseUrl: string = this.demoSettings.apiUrl;

    try {
      const url: string = new URL("/api/menu/items", baseUrl).href;

      const response: AxiosResponse = await axios.get(url);
      const { data }: { data: IMenuItems } = response;

      if (data) {
        this.menuSubject.next(data);
      }
    } catch (e) {
      const error = e as AxiosError;
      let errorMessage = undefined;

      if (error.response) {
        const { data, status } = error.response;
        const { message } = data;

        errorMessage = message || "Unable to fetch items";

        this.menuErrorSubject.next({ status, message: errorMessage });
      }
    }
  };

  clearError = async () => {
    this.menuErrorSubject.next(null);
  };
}

export const MenuService = new _MenuService();
