import React from "react";

import Content from "../layout/content";
import Button from "../ui/button";

import View from "../layout/view";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

import "./user-profile-view.scss";

interface IAvatarProps {
  picture: string;
  firstName: string;
}

const Avatar: React.FC<IAvatarProps> = (props: IAvatarProps) => {
  return (
    <img
      className="user-profile__avatar"
      src={props.picture}
      alt={props.firstName}
    />
  );
};

const UserProfileView: React.FC<WithAuth0Props> = props => {
  const { isLoading: isAuth0Loading, user } = props.auth0;

  if (isAuth0Loading) {
    return null;
  }

  if (!user) {
    return null;
  }

  return (
    <View>
      <Content title={user.name}>
        <Avatar picture={user.picture} firstName={user.name} />
        <Button label="View Account" action={() => {}} />
        <Button label="Help" action={() => {}} />
      </Content>
    </View>
  );
};

export default withAuth0(UserProfileView);
