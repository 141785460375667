import React from "react";

import View, { ViewStates } from "../layout/view";
import Content from "../layout/content";
import { MenuService } from "../../services/menu.service";
import Button from "../ui/button";
import { IMenuError, IMenuItem, MenuFormValues } from "../../models/menu.types";

import { History } from "history";
import { Subscription } from "rxjs";
import ErrorBox from "../ui/error-box";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { menuItemSchema } from "../../models/menu-item.schema";
import FormField from "../ui/form-field";
import PillButton from "../ui/pill-button";

import "../ui/form.scss";
import { Form } from "../ui/form";

interface IEditItemViewProps {
  history: History;
  computedMatch: {
    params: {
      menuItemId: string;
    };
  };
}

const EditItemView: React.FC<IEditItemViewProps> = props => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [menuItem, setMenuItem] = React.useState<IMenuItem | undefined>(
    undefined
  );
  const [viewStatus, setViewStatus] = React.useState<ViewStates | undefined>(
    undefined
  );
  const [menuError, setMenuError] = React.useState<IMenuError | null>(null);
  const [isMenuErrorVisible, setIsMenuErrorVisible] = React.useState<boolean>(
    false
  );

  const menuItemId: number = parseInt(
    props.computedMatch.params.menuItemId,
    10
  );

  const history = useHistory();

  const { formState, reset, register, errors, handleSubmit, trigger } = useForm<
    MenuFormValues
  >({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(menuItemSchema)
  });

  const { isValid } = formState;

  React.useEffect(() => {
    trigger();
  }, [trigger, menuItem]);

  React.useEffect(() => {
    const menuErrorSub: Subscription = MenuService.observeMenuError$().subscribe(
      menuError => {
        if (menuError) {
          setMenuError(menuError);
          setIsMenuErrorVisible(true);
        }
      }
    );

    const selectedMenuItemSub: Subscription = MenuService.observeSelectedMenuItem$().subscribe(
      async selectedMenuItem => {
        if (selectedMenuItem) {
          setViewStatus(ViewStates.Valid);
          setMenuItem(selectedMenuItem);
          setLoading(false);

          return;
        }

        const fetchedMenuItem = await MenuService.getMenuItem(menuItemId);

        if (fetchedMenuItem) {
          setViewStatus(ViewStates.Valid);
          setMenuItem(fetchedMenuItem);
          setLoading(false);

          return;
        }

        setViewStatus(ViewStates.NotFound);
        setLoading(false);
      }
    );

    return () => {
      menuErrorSub.unsubscribe();
      selectedMenuItemSub.unsubscribe();
    };
  }, [menuItemId]);

  const onClear = async () => {
    reset({
      name: "",
      description: "",
      price: "",
      image: ""
    });

    await MenuService.clearError();
  };

  const onSubmit = async (id: number, data: MenuFormValues) => {
    const updatedMenuItem: IMenuItem = { ...data, id };

    await MenuService.updateMenuItem(updatedMenuItem);
  };

  const cancel = async (): Promise<void> => {
    await MenuService.clearError();
    await history.push(`/menu/${menuItemId}`);
  };

  const toggleMenuErrorVisibility = async () => {
    if (isMenuErrorVisible) {
      setIsMenuErrorVisible(false);

      await MenuService.clearError;
    }
  };

  if (loading) {
    return null;
  }

  if (viewStatus === ViewStates.NotFound) {
    return (
      <View viewStatus={viewStatus}>
        <Button label="View menu items" action={() => history.push("/menu")} />
      </View>
    );
  }

  if (!menuItem) {
    return null;
  }

  if (viewStatus === ViewStates.Valid) {
    return (
      <View viewStatus={viewStatus}>
        <Content title="Edit Menu Item" actionName="Cancel" action={cancel}>
          <Form>
            <FormField
              required={true}
              defaultValue={menuItem.name}
              label="Name"
              inputName="name"
              errors={errors.name}
              register={register}
            />
            <FormField
              required={true}
              defaultValue={menuItem.price}
              label="Price (in cents)"
              inputName="price"
              errors={errors.price}
              register={register}
            />
            <FormField
              required={true}
              defaultValue={menuItem.description}
              label="Description"
              inputName="description"
              errors={errors.description}
              register={register}
            />
            <FormField
              required={true}
              defaultValue={menuItem.image}
              label="Image URL"
              inputName="image"
              errors={errors.image}
              register={register}
            />
          </Form>
          <PillButton
            enabled={isValid}
            label="Save"
            action={handleSubmit(data => onSubmit(menuItemId, data))}
          />
          <PillButton enabled={true} label="Clear" action={onClear} />
          {menuError && (
            <ErrorBox
              message={menuError.message}
              visible={isMenuErrorVisible}
              action={toggleMenuErrorVisibility}
            />
          )}
        </Content>
      </View>
    );
  }

  return null;
};

export default EditItemView;
