import React from "react";
import { combineLatest, Subscription } from "rxjs";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

import View from "../layout/view";
import Content from "../layout/content";
import GridItem from "../ui/grid-item";
import Grid from "../ui/grid";

import { MenuService } from "../../services/menu.service";

import { IMenuError, IMenuItem, IMenuItems } from "../../models/menu.types";
import { SettingsService } from "../../services/settings.service";
import { IDemoSettings } from "../../models/settings.types";
import { useHistory } from "react-router-dom";
import { SecurityService } from "../../services/security.service";

const MenuView: React.FC<WithAuth0Props> = ({ auth0 }) => {
  const [menuItems, setMenuItems] = React.useState<IMenuItem[] | undefined>(
    undefined
  );
  const [menuError, setMenuError] = React.useState<IMenuError | null>(null);
  const [hasAdminAccess, setHasAdminAccess] = React.useState<
    boolean | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [authentication, setAuthentication] = React.useState<
    boolean | undefined
  >(undefined);

  const history = useHistory();

  React.useEffect(() => {
    const dataSubscription: Subscription = combineLatest([
      MenuService.observeMenu$(),
      MenuService.observeMenuError$(),
      SecurityService.observeHasAdminAccess$(),
      SettingsService.observeSettings$()
    ]).subscribe(
      (
        data: [
          IMenuItems,
          IMenuError | null,
          boolean | undefined,
          IDemoSettings | undefined
        ]
      ) => {
        const [menuData, menuError, hasAdminAccess, settings]: [
          IMenuItems,
          IMenuError | null,
          boolean | undefined,
          IDemoSettings | undefined
        ] = data;

        if (menuError) {
          setMenuError(menuError);
          setLoading(false);

          return;
        }

        if (!settings) {
          return;
        }

        const menuItems: IMenuItem[] = Object.values(menuData);

        setMenuItems(menuItems);
        setHasAdminAccess(hasAdminAccess);
        setLoading(false);
        setAuthentication(settings.authentication);
      }
    );

    return () => dataSubscription.unsubscribe();
  }, []);

  const addMenuItem = (): void => {
    history.push(`/menu/add-item`);
  };

  if (authentication === undefined) {
    return null;
  }

  const { isAuthenticated } = auth0;

  const MenuViewBody: React.FC = () => {
    if (!menuItems) {
      return null;
    }

    if (menuItems.length === 0) {
      return (
        <>
          <h2>There are no items.</h2>

          {menuError && <span>{menuError}</span>}
        </>
      );
    }

    return (
      <>
        <Grid>
          {menuItems.map((menuItem: IMenuItem) => (
            <GridItem
              key={menuItem.id}
              {...menuItem}
              content={menuItem.image}
            />
          ))}
        </Grid>

        {menuError && <span>{menuError}</span>}
      </>
    );
  };

  if (loading) {
    return null;
  }

  if (!menuItems) {
    return null;
  }

  if (!authentication) {
    return (
      <View>
        <Content title="Menu Items" actionName="Add Item" action={addMenuItem}>
          <MenuViewBody />
        </Content>
      </View>
    );
  }

  if (authentication && isAuthenticated && hasAdminAccess) {
    return (
      <View>
        <Content title="Menu Items" actionName="Add Item" action={addMenuItem}>
          <MenuViewBody />
        </Content>
      </View>
    );
  }

  return (
    <View>
      <Content title="Menu Items">
        <MenuViewBody />
      </Content>
    </View>
  );
};

export default withAuth0(MenuView);
