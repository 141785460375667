import React from "react";

import View from "../layout/view";
import Content from "../layout/content";

import { MenuService } from "../../services/menu.service";

import { History } from "history";
import { Subscription } from "rxjs";
import {
  IBaseMenuItem,
  IMenuError,
  MenuFormValues
} from "../../models/menu.types";
import ErrorBox from "../ui/error-box";
import { useHistory } from "react-router-dom";
import FormField from "../ui/form-field";
import { useForm } from "react-hook-form";
import PillButton from "../ui/pill-button";
import { yupResolver } from "@hookform/resolvers/yup";
import { menuItemSchema } from "../../models/menu-item.schema";

import "../ui/form.scss";
import { Form } from "../ui/form";

interface IAddItemViewProps {
  history: History;
}

const AddItemView: React.FC<IAddItemViewProps> = () => {
  const [menuError, setMenuError] = React.useState<IMenuError | null>(null);
  const [isMenuErrorVisible, setIsMenuErrorVisible] = React.useState<boolean>(
    false
  );

  const history = useHistory();
  const { register, errors, handleSubmit, formState, reset } = useForm<
    MenuFormValues
  >({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(menuItemSchema)
  });
  const { isValid } = formState;

  const cancel = async (): Promise<void> => {
    await MenuService.clearError();
    history.push("/menu");
  };

  const onSubmit = async (data: MenuFormValues) => {
    const newMenuItem: IBaseMenuItem = { ...data };

    await MenuService.addMenuItem(newMenuItem);
  };

  const toggleMenuErrorVisibility = async () => {
    if (isMenuErrorVisible) {
      setIsMenuErrorVisible(false);

      await MenuService.clearError();
    }
  };

  const onClear = async () => {
    reset({
      name: "",
      description: "",
      price: "",
      image: ""
    });

    await MenuService.clearError();
  };

  React.useEffect(() => {
    const settingsSubscription: Subscription = MenuService.observeMenuError$().subscribe(
      menuError => {
        if (menuError) {
          setMenuError(menuError);
          setIsMenuErrorVisible(true);
        }
      }
    );

    return () => {
      settingsSubscription.unsubscribe();
    };
  }, []);

  return (
    <View>
      <Content title="Add Menu Item" actionName="Cancel" action={cancel}>
        <Form>
          <FormField
            required={true}
            defaultValue="Spring Salad"
            label="Name"
            inputName="name"
            errors={errors.name}
            register={register}
          />
          <FormField
            required={true}
            defaultValue={599}
            label="Price (in cents)"
            inputName="price"
            errors={errors.price}
            register={register}
          />
          <FormField
            required={true}
            defaultValue="Fresh"
            label="Description"
            inputName="description"
            errors={errors.description}
            register={register}
          />
          <FormField
            required={true}
            defaultValue="https://cdn.auth0.com/blog/whatabyte/salad-sm.png"
            label="Image URL"
            inputName="image"
            errors={errors.image}
            register={register}
          />
        </Form>
        <PillButton
          enabled={isValid}
          label="Save"
          action={handleSubmit(onSubmit)}
        />
        <PillButton enabled={true} label="Clear" action={onClear} />

        {menuError && (
          <ErrorBox
            message={menuError.message}
            visible={isMenuErrorVisible}
            action={toggleMenuErrorVisibility}
          />
        )}
      </Content>
    </View>
  );
};

export default AddItemView;
