import * as yup from "yup";

export const demoSettingsSchema = yup.object().shape({
  authentication: yup.boolean(),
  apiUrl: yup
    .string()
    .required("Required")
    .matches(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/, {
      message: "must be a valid URL"
    }),
  domain: yup.string().when("authentication", {
    is: true,
    then: yup
      .string()
      .required("Required")
      .matches(/[a-z0-9]+(?:-+[a-z0-9]+)*(\.(au|eu|jp|us))?\.auth0\.com/, {
        message: "must be a valid Auth0 domain"
      })
  }),
  clientId: yup.string().when("authentication", {
    is: true,
    then: yup.string().required("Required")
  }),
  audience: yup.string().when("authentication", {
    is: true,
    then: yup.string().required("Required")
  }),
  callbackUrl: yup.string().when("authentication", {
    is: true,
    then: yup
      .string()
      .required("Required")
      .matches(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/, {
        message: "must be a valid URL"
      })
  }),
  rbac: yup.boolean(),
  userRole: yup.string().when("rbac", {
    is: true,
    then: yup.string().required("Required")
  })
});
