import * as yup from "yup";

export const menuItemSchema = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
  price: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .typeError("must be a number")
    .required("Required")
    .integer("must be an integer")
    .positive("must be greater than 0"),
  image: yup
    .string()
    .required("Required")
    .matches(/^(https:\/\/).+(\.[a-z]{2,3}\/).+(\.(jpg|jpeg|png))$/, {
      message: "must be a valid image URL (.jpg, .jpeg, .png)"
    })
});
