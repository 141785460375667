import {
  IAuthenticationSettings,
  IAuthenticationSettingsFields,
  ICoreSettings,
  ICoreSettingsFields,
  IDemoSettings,
  IDemoSettingsFields,
  ISettingsStateFlags
} from "../models/settings.types";

export const DB_SETTINGS_FLAG: string = "wab_demo_v2_settings_flag";
export const DB_SETTINGS: string = "wab_demo_v2_settings";

export const SETTINGS_STATE_FLAGS: ISettingsStateFlags = {
  SET: "set",
  UNSET: "unset"
};

export const CORE_DEFAULT_SETTINGS: ICoreSettings = {
  apiUrl: "http://localhost:7000",
  authentication: false,
  rbac: false
};

export const AUTHENTICATION_DEFAULT_SETTINGS: IAuthenticationSettings = {
  domain: "",
  clientId: "",
  callbackUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/home"
      : "https://dashboard.whatabyte.app/home",
  audience: "https://menu-api.example.com",
  userRole: ""
};

export const DEMO_DEFAULT_SETTINGS: IDemoSettings = {
  ...CORE_DEFAULT_SETTINGS,
  ...AUTHENTICATION_DEFAULT_SETTINGS
};

export const CORE_SETTINGS_FIELDS: ICoreSettingsFields = {
  apiUrl: {
    id: "apiUrl",
    name: "API Server Base URL",
    defaultValue: DEMO_DEFAULT_SETTINGS.apiUrl,
    isRequired: true
  }
};

export const AUTHENTICATION_SETTINGS_FIELDS: IAuthenticationSettingsFields = {
  domain: {
    id: "domain",
    name: "Auth0 Domain",
    defaultValue: DEMO_DEFAULT_SETTINGS.domain,
    isRequired: true
  },
  clientId: {
    id: "clientId",
    name: "Auth0 Client ID",
    defaultValue: DEMO_DEFAULT_SETTINGS.clientId,
    isRequired: true
  },
  callbackUrl: {
    id: "callbackUrl",
    name: "Auth0 Callback URL",
    defaultValue:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/home"
        : "https://dashboard.whatabyte.app/home",
    isRequired: true
  },
  audience: {
    id: "audience",
    name: "Auth0 API Audience",
    defaultValue: DEMO_DEFAULT_SETTINGS.audience,
    isRequired: true
  },
  userRole: {
    id: "userRole",
    name: "User Role",
    defaultValue: DEMO_DEFAULT_SETTINGS.userRole,
    isRequired: false
  }
};

export const DEMO_SETTINGS_FIELDS: IDemoSettingsFields = {
  ...CORE_SETTINGS_FIELDS,
  ...AUTHENTICATION_SETTINGS_FIELDS
};

export const INITIAL_SETTINGS: IDemoSettings = DEMO_DEFAULT_SETTINGS;
