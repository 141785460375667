import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { Subscription } from "rxjs";
import { SettingsService } from "../../services/settings.service";

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const [domain, setDomain] = React.useState<string | undefined>(undefined);
  const [clientId, setClientId] = React.useState<string | undefined>(undefined);
  const [audience, setAudience] = React.useState<string | undefined>(undefined);
  const [callbackUrl, setCallbackUrl] = React.useState<string | undefined>(
    undefined
  );

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push((appState && appState.returnTo) || window.location.pathname);
  };

  React.useEffect(() => {
    const demoSettingsSubscription: Subscription = SettingsService.observeSettings$().subscribe(
      settings => {
        if (!settings) {
          return;
        }

        setDomain(settings.domain);
        setClientId(settings.clientId);
        setAudience(settings.audience);
        setCallbackUrl(settings.callbackUrl);
      }
    );

    return () => {
      demoSettingsSubscription.unsubscribe();
    };
  }, []);

  if (!(domain && clientId && callbackUrl && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={callbackUrl}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
