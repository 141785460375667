import { BehaviorSubject, Observable } from "rxjs";
import * as localForage from "localforage";
import { IDemoSettings } from "../models/settings.types";

import {
  DB_SETTINGS,
  DB_SETTINGS_FLAG,
  INITIAL_SETTINGS,
  SETTINGS_STATE_FLAGS
} from "./settings.config";

class _SettingService {
  private settingsSub: BehaviorSubject<
    IDemoSettings | undefined
  > = new BehaviorSubject<IDemoSettings | undefined>(undefined);

  initialize = async () => {
    const setFlag: string | null = await localForage.getItem(DB_SETTINGS_FLAG);
    const dbSettings: IDemoSettings | null = await localForage.getItem(
      DB_SETTINGS
    );

    if (!setFlag) {
      this.settingsSub.next(INITIAL_SETTINGS);
      return;
    }

    if (setFlag === SETTINGS_STATE_FLAGS.UNSET && !dbSettings) {
      this.settingsSub.next(INITIAL_SETTINGS);
      return;
    }

    if (setFlag === SETTINGS_STATE_FLAGS.UNSET && dbSettings) {
      this.settingsSub.next(dbSettings);
      return;
    }

    if (setFlag === SETTINGS_STATE_FLAGS.SET && !dbSettings) {
      this.settingsSub.next(INITIAL_SETTINGS);
      return;
    }

    if (setFlag === SETTINGS_STATE_FLAGS.SET && dbSettings) {
      this.settingsSub.next(dbSettings);
      return;
    }
  };

  observeSettings$ = (): Observable<IDemoSettings | undefined> =>
    this.settingsSub.asObservable();

  get settings(): IDemoSettings | undefined {
    return this.settingsSub.getValue();
  }

  set settings(newSettings: IDemoSettings | undefined) {
    this.settingsSub.next(newSettings);
  }

  save = async (settings: IDemoSettings): Promise<void> => {
    let validSettings: boolean = true;

    if (!settings) {
      validSettings = false;
    }

    if (!settings.apiUrl) {
      validSettings = false;
    }

    if (settings.authentication) {
      if (
        !(
          settings.domain &&
          settings.clientId &&
          settings.callbackUrl &&
          settings.audience
        )
      ) {
        validSettings = false;
      }
    }

    if (settings.rbac) {
      if (!settings.userRole) {
        validSettings = false;
      }
    }

    if (validSettings) {
      await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.SET);
      await localForage.setItem(DB_SETTINGS, settings);

      this.settings = settings;
    }
  };

  clear = async () => {
    const state: IDemoSettings | undefined = this.settingsSub.getValue();

    if (state === undefined) {
      return;
    }

    Object.keys(state).map((key: string) => (state[key] = ""));

    await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.UNSET);

    this.settings = state;
  };
}

export const SettingsService: _SettingService = new _SettingService();
