import React from "react";

import "./toggle-button.scss";

interface IToggleButtonProps {
  enabled: boolean;
  toggleAction: () => void;
}

export const ToggleButton: React.FC<IToggleButtonProps> = props => {
  const { enabled, toggleAction } = props;

  const toggleContainerDisabledClass =
    !enabled && "toggle__container--disabled";

  const toggleButtonDisabledClass = !enabled && "toggle__button--disabled";

  return (
    <div className="toggle__wrapper">
      <div
        className={`toggle__container ${toggleContainerDisabledClass}`}
        onClick={toggleAction}
      >
        <div className={`toggle__button ${toggleButtonDisabledClass}`} />
      </div>
    </div>
  );
};
