import React from "react";

import "./data-field.scss";

export interface ITableField {
  name: string;
  value: string | boolean;
}

export interface ITableFields {
  [id: string]: ITableField;
}

interface IDataFieldLabelProps {
  label: string;
}

interface IDataFieldValueProps {
  value: string | boolean;
}

interface IDataFieldProps {
  label: string;
  value: string | boolean;
}

const DataFieldLabel: React.FC<IDataFieldLabelProps> = props => (
  <div className="data-field__label">{props.label}</div>
);

const DataFieldValue: React.FC<IDataFieldValueProps> = props => (
  <div className="data-field__value">{props.value}</div>
);

export const DataField: React.FC<IDataFieldProps> = ({ label, value }) => {
  return (
    <div className="data-field" key={label}>
      <DataFieldLabel label={label} />
      <DataFieldValue value={value} />
    </div>
  );
};
