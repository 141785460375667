import React from "react";

import View, { ViewStates } from "../layout/view";
import Content from "../layout/content";

import { MenuService } from "../../services/menu.service";
import Button from "../ui/button";
import { IMenuError, IMenuItem } from "../../models/menu.types";

import { History } from "history";
import { Subscription } from "rxjs";

import ErrorBox from "../ui/error-box";

import "./delete-item-view.scss";
import PillButton from "../ui/pill-button";

interface IDeleteItemViewState {
  loading: boolean;
  menuItem: IMenuItem | undefined;
  viewStatus: ViewStates.Valid | ViewStates.NotFound | undefined;
  menuItemId: number;
  menuError: IMenuError | null;
  isMenuErrorVisible: boolean;
}

interface IDeleteItemViewProps {
  history: History;
  computedMatch: {
    params: {
      menuItemId: string;
    };
  };
}

class DeleteItemView extends React.Component<
  IDeleteItemViewProps,
  IDeleteItemViewState
> {
  state: IDeleteItemViewState = {
    loading: true,
    menuItem: undefined,
    viewStatus: undefined,
    menuItemId: parseInt(this.props.computedMatch.params.menuItemId, 10),
    menuError: null,
    isMenuErrorVisible: false
  };

  menuErrorSub: Subscription | undefined = undefined;

  async componentDidMount() {
    this.menuErrorSub = MenuService.observeMenuError$().subscribe(menuError => {
      if (menuError) {
        this.setState({ menuError, isMenuErrorVisible: true });
      }
    });

    let menuItem: IMenuItem | undefined;
    let viewStatus: ViewStates.Valid | ViewStates.NotFound;
    let menuItemId: number = this.state.menuItemId;

    if (MenuService.selectedMenuItem) {
      menuItem = MenuService.selectedMenuItem;
    } else {
      menuItem = await MenuService.getMenuItem(menuItemId);
    }

    if (menuItem) {
      viewStatus = ViewStates.Valid;
    } else {
      viewStatus = ViewStates.NotFound;
    }

    this.setState({
      viewStatus,
      menuItem,
      loading: false,
      menuItemId
    });
  }

  componentWillUnmount(): void {
    if (this.menuErrorSub) {
      this.menuErrorSub.unsubscribe();
    }
  }

  deleteMenuItem = async (itemId: number) => {
    await MenuService.deleteMenuItem(itemId);
  };

  cancel = async (): Promise<void> => {
    await MenuService.clearError();
    await this.props.history.push(`/menu/${this.state.menuItemId}`);
  };

  async toggleMenuErrorVisibility() {
    if (this.state.isMenuErrorVisible) {
      this.setState(
        { isMenuErrorVisible: false },
        await MenuService.clearError
      );
    }
  }

  render() {
    return (
      !this.state.loading && (
        <>
          {this.state.viewStatus === ViewStates.NotFound && (
            <View viewStatus={this.state.viewStatus}>
              <Button
                label="View menu items"
                action={() => this.props.history.push("/menu")}
              />
            </View>
          )}

          {this.state.viewStatus === ViewStates.Valid && (
            <View viewStatus={this.state.viewStatus}>
              <Content
                title="Delete Menu Item"
                actionName="Back"
                action={this.cancel}
              >
                {this.state.menuItem && (
                  <div className="delete-item__confirmation-box">
                    <span>Are you sure you want to delete this item?</span>
                    <span className="delete-item__warning-text">
                      This action can't be undone.
                    </span>
                    <div className="delete-item__item-box">
                      <div
                        className="delete-item__item-image"
                        style={{
                          background: `url(${this.state.menuItem.image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "auto 100%",
                          backgroundPosition: "center"
                        }}
                      />
                      <div className="delete-item__item-info">
                        <span className="delete-item__item-name">
                          {this.state.menuItem.name}
                        </span>
                        <span className="delete-item__item-description">
                          {this.state.menuItem.description}
                        </span>
                      </div>
                    </div>
                    <div className="delete-item__options">
                      <PillButton action={() => this.cancel()} label="Cancel" />
                      <PillButton
                        action={() =>
                          this.state.menuItem &&
                          this.deleteMenuItem(this.state.menuItem.id)
                        }
                        label="Delete"
                      />
                    </div>
                  </div>
                )}
                {this.state.menuError && (
                  <ErrorBox
                    message={this.state.menuError.message}
                    visible={this.state.isMenuErrorVisible}
                    action={this.toggleMenuErrorVisibility.bind(this)}
                  />
                )}
              </Content>
            </View>
          )}
        </>
      )
    );
  }
}

export default DeleteItemView;
