import React from "react";

import View from "../layout/view";
import Content from "../layout/content";
import { SettingsService } from "../../services/settings.service";

import { DemoService } from "../../services/demo.service";
import { Subscription } from "rxjs";
import { IDemoSettings, SettingsFormValues } from "../../models/settings.types";

import "./demo-settings-view.scss";
import FormField from "../ui/form-field";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PillButton from "../ui/pill-button";
import { ToggleButton } from "../ui/toggle-button";
import { demoSettingsSchema } from "../../models/demo-settings.schema";

const DemoSettingsView: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
    setValue,
    control,
    reset,
    trigger
  } = useForm<SettingsFormValues>({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(demoSettingsSchema)
  });

  const { authentication, rbac } = watch();
  const { isValid } = formState;

  React.useEffect(() => {
    const settingsSubscription: Subscription = SettingsService.observeSettings$().subscribe(
      async (demoSettings: IDemoSettings | undefined) => {
        if (!demoSettings) {
          return;
        }

        const formData: SettingsFormValues = {
          apiUrl: demoSettings.apiUrl,
          authentication: demoSettings.authentication,
          domain: demoSettings.domain,
          clientId: demoSettings.clientId,
          callbackUrl: demoSettings.callbackUrl,
          audience: demoSettings.audience,
          rbac: demoSettings.rbac,
          userRole: demoSettings.userRole
        };

        reset(formData);

        setLoading(false);
      }
    );

    return () => settingsSubscription.unsubscribe();
  }, [reset]);

  React.useEffect(() => {
    if (!authentication && rbac) {
      return;
    }

    if (authentication) {
      trigger();
    }
  }, [authentication, rbac, trigger]);

  const onSubmit = async (data: SettingsFormValues) => {
    const newSettings: IDemoSettings = {
      apiUrl: data.apiUrl,
      authentication: data.authentication,
      audience: data.audience,
      callbackUrl: data.callbackUrl,
      clientId: data.clientId,
      domain: data.domain,
      rbac: data.rbac,
      userRole: data.userRole
    };

    await DemoService.makeReady(newSettings);
    await DemoService.transitionToReady();
  };

  const onClear = async () => {
    await SettingsService.clear();
  };

  if (loading) {
    return null;
  }

  return (
    <View>
      <Content title="Auth0 Demo Settings">
        <>
          {
            <>
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="demo-features__container">
                  <div className="demo-feature">
                    <h2 className="demo-feature__label">API Configuration</h2>
                  </div>
                </div>
                <FormField
                  required={true}
                  label="API Server Base URL"
                  inputName="apiUrl"
                  errors={errors.apiUrl}
                  register={register}
                />
                {/*<div>*/}
                {/*  <label htmlFor="authentication">Enable Authentication</label>*/}
                {/*  <input*/}
                {/*    id="authentication"*/}
                {/*    name="authentication"*/}
                {/*    type="checkbox"*/}
                {/*    ref={register}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="demo-features__container">
                  <div className="demo-feature">
                    <h2
                      className={`demo-feature__label ${!authentication &&
                        `demo-feature__label--disabled`}`}
                    >
                      Enable Authentication Features
                    </h2>
                    <Controller
                      defaultValue={false}
                      control={control}
                      name="authentication"
                      render={props => {
                        return (
                          <ToggleButton
                            enabled={authentication}
                            toggleAction={async () => {
                              setValue("authentication", !props.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                {authentication && (
                  <>
                    <FormField
                      required={true}
                      label="Auth0 Domain"
                      inputName="domain"
                      errors={errors.domain}
                      register={register}
                    />
                    <FormField
                      required={true}
                      label="Auth0 Client ID"
                      inputName="clientId"
                      errors={errors.clientId}
                      register={register}
                    />
                    <FormField
                      required={true}
                      label="Auth0 Auth0 Callback URL"
                      inputName="callbackUrl"
                      errors={errors.callbackUrl}
                      register={register}
                    />
                    <FormField
                      required={true}
                      label="Auth0 API Audience"
                      inputName="audience"
                      errors={errors.audience}
                      register={register}
                    />
                    {/*<div>*/}
                    {/*  <label htmlFor="rbac">*/}
                    {/*    Enable Role-Based Access Control (RBAC)*/}
                    {/*  </label>*/}
                    {/*  <input*/}
                    {/*    id="rbac"*/}
                    {/*    name="rbac"*/}
                    {/*    type="checkbox"*/}
                    {/*    ref={register}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="demo-features__container">
                      <div className="demo-feature">
                        <h2
                          className={`demo-feature__label ${!rbac &&
                            `demo-feature__label--disabled`}`}
                        >
                          Enable Role-Based Access Control (RBAC)
                        </h2>
                        <Controller
                          defaultValue={false}
                          control={control}
                          name="rbac"
                          render={props => {
                            return (
                              <ToggleButton
                                enabled={rbac}
                                toggleAction={() => {
                                  setValue("rbac", !props.value);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    {rbac && (
                      <FormField
                        required={true}
                        label="User Role"
                        inputName="userRole"
                        errors={errors.userRole}
                        register={register}
                      />
                    )}
                  </>
                )}
              </form>
              <PillButton
                enabled={isValid}
                label="Save"
                action={handleSubmit(onSubmit)}
              />
              <PillButton enabled={true} label="Clear" action={onClear} />
            </>
          }
        </>
      </Content>
    </View>
  );
};

export default DemoSettingsView;
