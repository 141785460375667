export interface IDemoStates {
  INITIALIZING: string;
  INITIALIZED: string;
  READY: string;
}

export enum DemoState {
  INITIALIZING = "[DEMO_STATES] INITIALIZING",
  INITIALIZED = "[DEMO_STATES] INITIALIZED",
  READY = "[DEMO_STATES] READY"
}
