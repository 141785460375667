import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Demo from "./demo";
import { Router } from "react-router-dom";
import history from "./history";

ReactDOM.render(
  <Router history={history}>
    <Demo />
  </Router>,
  document.getElementById("root")
);
