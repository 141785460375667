import React, { ComponentClass, FunctionComponent } from "react";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { Redirect, Route } from "react-router-dom";
import { Subscription } from "rxjs";
import { SettingsService } from "../../services/settings.service";
import { IDemoSettings } from "../../models/settings.types";

interface IProtectedRouteProps extends WithAuth0Props {
  component: ComponentClass<any, any> | FunctionComponent<any>;
  path: string;
  canActivate?: boolean;
  redirectPath?: string;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = props => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [authentication, setAuthentication] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    const settingsSubscription: Subscription = SettingsService.observeSettings$().subscribe(
      (settings: IDemoSettings | undefined) => {
        if (!settings) {
          return;
        }

        setLoading(false);
        setAuthentication(settings.authentication);
      }
    );

    return () => settingsSubscription.unsubscribe();
  }, []);

  if (authentication === undefined) {
    return null;
  }

  const Component = props.component;

  if (!authentication) {
    return !loading ? (
      <Route path={props.path} render={() => <Component {...props} />} />
    ) : null;
  }

  if (authentication) {
    const canActivate =
      props.canActivate === undefined ? true : props.canActivate;

    const { isAuthenticated } = props.auth0;

    return !loading ? (
      isAuthenticated && canActivate ? (
        <Route path={props.path} render={() => <Component {...props} />} />
      ) : (
        <Redirect to={props.redirectPath || "/home"} from="" />
      )
    ) : null;
  }

  return null;
};

export default withAuth0(ProtectedRoute);
